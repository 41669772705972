<template>
  <a-card>
    <div slot="title">添加员工</div>
    <basis-form ref="basis" />
    <a-row>
      <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
        <a-button type="primary" shape="round" style="width:160px" @click="onSubmit">
          确认
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import BasisForm from './components/BasisForm'
import * as userApi from '@/api/user'
import events from '@/components/MultiTab/events'
export default {
  name: 'PermissionUserCreate',
  components: {
    BasisForm
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.$refs.basis.initData()
    },
    onSubmit() {
      const {
        $refs: { basis },
        $notification
      } = this
      basis.validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = basis
            const form = formatForm()
            userApi
              .create({ ...form })
              .then(res => {
                const { code, msg } = res
                if (code === 0) {
                  this.close()
                  $notification['success']({ message: '操作成功' })
                } else {
                  $notification['error']({ message: `${msg}` })
                }
              })
              .finally(() => (this.loading = false))
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.add-tips {
  font-size: 18px;
  color: #595959;
}
</style>
